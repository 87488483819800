// import React, { useState, useEffect } from 'react';
import SlideshowContainer from "../../containers/blocks/public/slideshow-container";
import HeaderAnimatedContainer from "../../containers/common/header-animated-container";
import Features from "../blocks/public/features";
import GetOmegaLifeContainer from "../../containers/blocks/public/get-container";
// import GetHolding from "../blocks/public/get-holding";
import How from "../blocks/public/how";
import Footer from "../common/footer";
import Questions from "../blocks/public/questions";
import ContactContainer from "../../containers/blocks/public/contact-container";


function Home() {

    // Home
    return (
            <div className="container">
                
                <HeaderAnimatedContainer />

                <SlideshowContainer />
                <Features />
                <How />
                <Questions />
                <GetOmegaLifeContainer />
                <ContactContainer />

                <Footer />

            </div>
    );
}

export default Home;
