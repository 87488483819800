function Text(props) {

    console.log(props.valid);

    return (
        <div className="input text">
            <label htmlFor={props.fieldName}>{ props.label }</label>
            <input type={ typeof props.type != "undefined" ? props.type : "text" } id={props.fieldName } name={ props.fieldName } onChange={ (e) => props.handleInput(e, props.fieldName, e.target.value) } />
            { typeof props.valid[props.fieldName] != "undefined" && 
                <p className="validation-error">{props.valid[props.fieldName] }</p>
            }
        </div>
    );
}

export default Text;
