import CatherineImg from './../../../assets/img/reviews/catherine.webp';

function Slideshow(props) {

    return (
        <section className="header-img">
            <div className='content'>

                <div className="width-12 title-tag">
                    <h1>Supporting you as you care for your loved ones.</h1>
                    <h3>OmegaLife is a mobile app and smart kit that helps give your loved ones the freedom to stay in their home, whilst giving you the assurance that they are safe and cared for.</h3>
                </div>

                <div className="width-6 review">
                    <div className="reviewer-photo" style={{ backgroundImage: "url('" + CatherineImg + "')" }}></div>
                    <div className="review-content">
                        <p>I am very impressed with this app. Mum had a health scare recently so to be able to keep an eye on her in this unobtrusive way gives me peace of mind.</p>
                        <p className="reviewer">~ Catherine, Surrey</p>
                    </div>
                </div>

                <div className="devices-cont">&nbsp;</div>

                <div className="width-6 intro right">
                    <p>Caring for elderly, infirm or vulnerable loved ones can be difficult. Handling the stresses and strains of your everyday life, whilst supporting and caring for others, can be draining.</p>
                    <p>OmegaLife is here to support you in this. Our mobile app and integrated hardware give insights into habits and regular activity - allowing carers to tailor the app to monitor the vulnerable individual&rsquo;s specific behaviours. It can then trigger alerts for potential incidents or problems as they happen.</p>
                    <p>As a result, OmegaLife gives vulnerable and infirm people the freedom to stay in their home, whilst giving their carers the assurance that they are supporting their safety and well being.</p>
                </div>

                <div className="width-6 explainer">
                    <iframe src="https://player.vimeo.com/video/810720334?h=a6d96d9df4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="OmegaLife Explainer video - HD"></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>

            </div>
        </section>
    );
}

export default Slideshow;
