import { Fragment } from "react";

function Contact(props) {

    var valErrs = {};
    for (var i in props.valErrs) {
        valErrs[props.valErrs[i].field] = props.valErrs[i].error;
    }
    console.log(valErrs);

    return (
        <Fragment>

            <section className={ props.isPrivacy ? "contact dark" : "contact"}>
                <div className='content'>

                    <div className="width-12">
                        { props.isPrivacy ? (
                            <h2>Managing my data</h2>
                        ) : (
                            <h2>Get in touch</h2>
                        )}
                    </div>

                    <div className="width-2 spacer">&nbsp;</div>
                    {props.isPrivacy ? (
                        <div className="width-4">
                            <p>We respect our customers and your right to privacy and control over your personal data.</p>
                            <p>If you would like to make a request regarding your personal data (e.g. to request that it is removed), you can get in touch using this form and a member of our team will get back to you.</p>
                        </div>
                    ) : (
                        <div className="width-4">
                            <p>Want to find out more about OmegaLife and how it can help you?</p>
                            <p>We&rsquo;re always delighted to hear from new people, so just drop us a message using the form on the right.</p>
                        </div>
                    )}

                    <div className="width-4 form">
                        { props.error &&
                            <div className={ "flash-msg " + props.error.type }>
                                <p>{props.error.msg}</p>
                            </div>
                        }
                        <form name="contact">
                            <input type="text" name="name" placeholder="Your name" onChange={ (e) => props.updateForm(e) } />
                            { typeof valErrs.Name !== "undefined" && 
                                <p className="val-error">{ valErrs.Name }</p>
                            }

                            <input type="email" name="emailAddress" placeholder="Your email address" onChange={(e) => props.updateForm(e)} />
                            { typeof valErrs.EmailAddress !== "undefined" && 
                                <p className="val-error">{ valErrs.EmailAddress }</p>
                            }

                            <input type="tel" name="phone" placeholder="Your phone number" onChange={(e) => props.updateForm(e)} />
                            { typeof valErrs.Phone !== "undefined" && 
                                <p className="val-error">{ valErrs.Phone }</p>
                            }

                            <input type="text" name="subject" placeholder="Message subject" onChange={(e) => props.updateForm(e)} className="hidden" />
                            <textarea name="message" placeholder="Your message" onChange={(e) => props.updateForm(e)}></textarea>
                            {typeof valErrs.Message !== "undefined" &&
                                <p className="val-error">{valErrs.Message}</p>
                            }

                            <button className="btn" onClick={ (e) => props.sendMsg(e) }>{ props.btnTxt }</button>
                        </form>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default Contact;
