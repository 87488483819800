import React, { useState } from "react";

import Fetching from './../../../utilities/fetching';
import Contact from "../../../components/blocks/public/contact";

function ContactContainer(props) {

    const [btnTxt, setBtnTxt] = useState("Send message");
    const [error, setError] = useState(false);
    const [contactDetails, setContactDetails] = useState({ name: "", emailAddress: "", phone: "", message: "", subject: "" });
    const [valErrs, setValErrs] = useState([]);

    // Handle the form population
    const updateForm = (e) => {

        e.preventDefault();
        var newDetails = { ...contactDetails }
        newDetails[e.target.name] = e.target.value;
        setContactDetails(newDetails);

    } 

    // Complete the subscription / registration 
    const sendMsg = (e) => {

        e.preventDefault();
        setError(false);
        setBtnTxt("Sending...");
        setValErrs([]);

        // Tweak the contact details depending on the page
        if (typeof props.isPrivacy != "undefined" && props.isPrivacy) {
            contactDetails.type = "data";
        } else {
            contactDetails.type = "msg";
        }

        // Submit the data
        fetch(process.env.REACT_APP_API_BASE + '/leads/send', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(contactDetails)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setBtnTxt("Send message");
            
            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setError({ msg: "Thanks for your message. We will be in touch shortly.", type: "success"});
                return;
            }
            
            // Handle errors
            setError({ msg: "Sorry but there was an error sending your message. Please check your details below and try again.", type: "error" });
            setValErrs(data.detail);
            
        })
        .catch(function (error) {
            setBtnTxt("Send message");
            setError({ msg: "Sorry but there was an error sending your message. Please check the details you entered below and try again.", type: "error" });
            console.log('Request failed', error);
        });

    }

    return (
        <Contact 
            updateForm={ updateForm }
            btnTxt={ btnTxt }
            sendMsg={ sendMsg }
            error={ error }
            valErrs={ valErrs }
            isPrivacy={ typeof props.isPrivacy != "undefined" ? props.isPrivacy : false }
            {...props} 
        />
    );
}

export default ContactContainer;
