// import React, { useState, useEffect } from 'react';
import HeaderAnimatedContainer from "../../containers/common/header-animated-container";
import Footer from "../common/footer";
import ContactContainer from "../../containers/blocks/public/contact-container";

function Privacy() {

    // Home
    return (
            <div className="container">
                
                <HeaderAnimatedContainer isNonHome={true} />
                <ContactContainer isPrivacy={true} />

                <Footer />

            </div>
    );
}

export default Privacy;
