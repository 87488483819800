import React, {useState} from 'react';

function Questions(props) {

    const [open, setOpen] = useState(false);

    const toggleOpen = (e, id) => {
        e.preventDefault();
        if (open === id) {
            setOpen(false);
        } else {
            setOpen(id);
        }
    }

    return (
        <section className="questions">
            <div className='content'>

                <div className="width-6 centred">
                    <h2>Common questions</h2>
                    <p className="prominent">We believe OmegaLife offers a technical solution for people from a whole range of different backgrounds. If you have any questions about your specific circumstances, feel free to get in touch - we have put a few of the most common questions and answers on right.</p>
                </div>
                
                
                <div className="width-6 centred accordian">
                    
                    <div className={open === 1 ? "question openned" : "question"}>
                        <h4><a href="#open" onClick={ (e) => toggleOpen(e, 1) }>How does OmegaLife work?</a></h4>
                        <div className={open === 1 ? "open" : ""}>
                            <p>OmegaLife consists of a central mobile app and a smart kit that work together to monitor activity, alert to incidents and facilitate communication between carers.</p>
                            <p>The smart kit (a central base unit, emergency buttons, motion sensors, door sensors and smart plugs) is installed in the home of the vulnerable person. This kit communicates with the mobile app about activity in that person&rsquo;s home / place of residence - allowing family, friends and carers who are using the app to understand typical behaviour.</p>
                            <p>This can then be used to configure the alerts within the app - to notify the users of incidents and potential issues. (E.g. trigger a phone call when an emergency button is pressed or an app-based “push” notification when a kettle is not boiled before 9am each day)</p>
                            <p>Finally, a chat function within the app allows those family, firends and carers to communicate, share information and decide on how to respond to any problems.</p>
                        </div>
                    </div>

                    <div className={open === 2 ? "question openned" : "question"}>
                        <h4><a href="#open" onClick={(e) => toggleOpen(e, 2)}>What do I need to set up the OmegaLife kit?</a></h4>
                        <div className={ open === 2 ? "open" : "" }>
                            <p>The OmegaLife smart kit requires a broadband connection in the vulnerable person&rsquo;s home / place of residence. This allows it to link to and communicate with the mobile app.</p>
                            <p><em>If you do not currently have a broadband connection, we would recommend the purchase of a 4G router, which can deliver the required internet connection for the OmegaLife setup. If you want some help finding a suitable unit, feel free to get in touch.</em></p>
                            <p>The mobile app itself runs on iOS and Android powered mobile phones and tablets. Any users or carers who wish to be kept informed will need such a device.</p>
                        </div>
                    </div>

                    <div className={open === 3 ? "question openned" : "question"}>
                        <h4><a href="#open" onClick={(e) => toggleOpen(e, 3)}>What happens if I lose power or internet connection?</a></h4>
                        <div className={open === 3 ? "open" : ""}>
                            <p>The OmegaLife smart kit sends regular check-in messages to the mobile app, with the app itself  set up to send you an alert when any smart devices are not &quot;seen&quot;. You will therefore be alerted to any significant issues with internet connection and / or power outages soon after they happen.</p>
                        </div>
                    </div>

                    <div className={open === 4 ? "question openned" : "question"}>
                        <h4><a href="#open" onClick={(e) => toggleOpen(e, 4)}>What do you do with my loved one&rsquo;s data?</a></h4>
                        <div className={open === 4 ? "open" : ""}>
                            <p>Data collected within the app is used purely for the delivery of the app&rsquo;s functionality. For more information about how we look after your data, please read our <a href="https://api.omega-life.co.uk/agreements/view/privacy" target="_blank" rel='noreferrer'>privacy policy</a>.</p>
                        </div>
                    </div>

                    <div className={open === 5 ? "question openned" : "question"}>
                        <h4><a href="#open" onClick={(e) => toggleOpen(e, 5)}>Can I install the kit myself?</a></h4>
                        <div className={open === 5 ? "open" : ""}>
                            <p>Yes. OmegaLife is designed to be as easy-to-install as possible.</p>
                            <p>Once the smart kit arrives, you simply need to install the mobile app, set up a password and follow the instructions in the app to link it to your smart devices.</p>
                            <p>You can then monitor activity, tailor the alerts to your loved one and communicate with your family and other carers through the app.</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default Questions;
