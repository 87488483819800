import React, { useState } from "react";

import Slideshow from "../../../components/blocks/public/slideshow";


function SlideshowContainer() {

    // Handle the state storage for key elements
    const [logoOpacity, setLogoOpacity] = useState(1);

    // Handle the deciding of logo Opacity
    const changeOpacity = () => {
        let scrollY = window.scrollY;
        let winHeight = window.innerHeight;
        
        // Calculate the opacity percentage scroll that has been done
        // (The logo should disappear when it reaches the top of the screen)
        var newOpacity = 1 - (scrollY * 2.25) / winHeight;
        setLogoOpacity(newOpacity);
    }

    window.addEventListener('scroll', changeOpacity)

    return (
        <Slideshow logoOpacity={ logoOpacity } />
    );
}

export default SlideshowContainer;
