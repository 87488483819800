import Text from './fields/text';

function Success(props) {

    return (
        <div className="cover">
            <div className="cover-box big">
                <button className="close-box" onClick={ (e) => props.closeAction(e) }>Close</button>
                <h3>Thank you for subscribing</h3>
                <p>Thanks for setting up a subscription to OmegaLife. In order to get you started with your account, we need a few more details from you using the form below.</p>
                <p>Once you have provided this information we will:</p>
                <ul>
                    <li>Send you a welcome email with instructions on how to set up the app</li>
                    <li>Post your OmegaLife sensors and set up pack to you</li>
                </ul>

                <h4>Complete sign-up</h4>

                { props.error && 
                    <p className='error'>{ props.error }</p>
                }

                <Text label="First name" fieldName="firstName" handleInput={ props.updateForm } valid={ props.valid } />
                <Text label="Last name" fieldName="lastName" handleInput={props.updateForm} valid={props.valid} />
                <Text label="Email address" fieldName="email" type={"email"} handleInput={props.updateForm} valid={props.valid} />

                <Text label="Address line 1" fieldName="address1" handleInput={props.updateForm} valid={props.valid} />
                <Text label="Address line 2" fieldName="address2" handleInput={props.updateForm} valid={props.valid} />
                <Text label="Address line 3" fieldName="address3" handleInput={props.updateForm} valid={props.valid} />
                <Text label="Post code" fieldName="postcode" handleInput={props.updateForm} valid={props.valid} />

                <button className='btn block' onClick={ props.completeRegistration }>Submit</button>

            </div>
        </div>
    );
}

export default Success;
