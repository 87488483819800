import SignUp from './../../../assets/img/icons/setup.webp';
import Relax from './../../../assets/img/icons/relax.webp';
import GetApp from './../../../assets/img/icons/get-app.webp';
import Users from './../../../assets/img/icons/setup-usrs.webp';
import Install from './../../../assets/img/icons/install.webp';

function How(props) {

    return (
        <section className="how" id="how">
            <div className='content'>

                <div className="width-8 centred panel">
                    
                    <h2>How it works</h2>
                    <p className="centred">Getting started with OmegaLife is really simple - just follow the steps below:</p>

                    <div className="panel-content">
                        <div className="item no-one">
                            <img src={SignUp} alt="Sign up" loading="lazy" />
                            <h4>1. Sign up</h4>
                            <p>Subscribe below and order the right OmegaLife kit for you and your loved one.</p>
                        </div>

                        <div className="item no-two">
                            <img src={GetApp} alt="Get the app" loading="lazy" />
                            <h4>2. Get the app</h4>
                            <p>Download the app from the Apple App Store or Google Play Store.</p>
                        </div>

                        <div className="item no-three">
                            <img src={Users} alt="Set up users" loading="lazy" />
                            <h4>3. Set up users</h4>
                            <p>Invite other family members, carers or friends to join your account.</p>
                        </div>

                        <div className="item no-four">
                            <img src={Install} alt="Install" loading="lazy" />
                            <h4>4. Install equipment</h4>
                            <p>Set up the equipment in your loved one&rsquo;s home - tell the app about their common behaviours.</p>
                        </div>

                        <div className="item no-five">
                            <img src={Relax} alt="Sit back and relax" loading="lazy" />
                            <h4>5. Relax</h4>
                            <p>Sit back and relax, safe in the knowledge that you will be alerted to any incidents affecting your loved one.</p>
                        </div>
                    </div>
                </div>

                

            </div>
        </section>
    );
}

export default How;
