import TSA from './../../assets/img/home/tsa.png';

function Footer() {

    return (
        <footer className="footer">
            
            <div className="content">
                <div className="partners">
                    <p>Proud partner of:</p>
                    <img src={ TSA } className="partner-logo" alt="TSA" loading="lazy" />
                </div>

                <section className="final">
                    <div className="width-6">
                        <p className="email"><a href="mailto:hello@omega-life.co.uk">Support Enquiries</a></p>
                    </div>

                    <div className="width-6 right">
                        <p>All content and design copyright &copy; 2022 Omega Analaytics Systems Ltd</p>
                    </div>
                </section>
            </div>

        </footer>
    );
}

export default Footer;
