import React, { useState } from "react";

import HeaderAnimated from "../../components/common/header-animated";


function HeaderAnimatedContainer( props ) {

    // Handle the state storage for key elements
    const [headOpacity, setHeadOpacity] = useState(0);

    // Handle scrolling to a given position on the pagejavascript
    const scrollToSxn = (e, sxnName) => {

        e.preventDefault();

        // Work out the section top position
        var top = getOffset(document.getElementById(sxnName)).top;

        // Scroll to that position
        window.scrollTo({
            top: top - 120,
            left: 0,
            behavior: 'smooth',
        });

    }

    // Get the offset of a position 
    const getOffset = (el) => {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    }

    // Handle the deciding of logo Opacity
    const changeOpacity = () => {
        let scrollY = window.scrollY;
        let winHeight = window.innerHeight;
        
        // Calculate the opacity percentage scroll that has been done
        // (The logo should disappear when it reaches the top of the screen)
        var newOpacity = (scrollY * 0.75) / winHeight;
        if (newOpacity > 0.8) newOpacity = 0.8;
        setHeadOpacity(newOpacity);
    }

    const addToFavourites = (e) => {

        e.preventDefault();

        // Get the current URL
        let url = window.location.href;

        // Get the current page title
        let title = document.title;

        // Try to add the bookmark
        try {
            window.external.AddFavorite(url, title);
        } catch (e) {
            try {
                window.sidebar.addPanel(title, url, "");
            } catch (e) {
                alert('Too save this page in your favourites, simply press ' + (navigator.userAgent.toLowerCase().indexOf('mac') !== -1 ? '"Cmd' : '"CTRL') + ' + D" on your keyboard.');
            }
        }
    }

    window.addEventListener('scroll', changeOpacity)

    return (
        <HeaderAnimated 
            headOpacity={ headOpacity } 
            scrollToSxn={ scrollToSxn } 
            addToFavourites={ addToFavourites } 
            isNonHome={typeof props.isNonHome != "undefined" ? props.isNonHome : false }
        />
    );
}

export default HeaderAnimatedContainer;
