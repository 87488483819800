import Speech from './../../../assets/img/icons/speech.png';
import Understand from './../../../assets/img/icons/understand.png';
import Alerts from './../../../assets/img/icons/alerts.png';

function Features(props) {

    return (
        <section className="features">
            <div className='content'>

                <div className="width-10 centred">
                    <h2>Key features</h2>
                </div>

                <div className="width-1 sml-hide">&nbsp;</div>
                <div className="width-4">
                    <p className="prominent">There are many reasons why you might need a technical care solution: caring for an elderly relative, supporting someone with a disability or keeping in touch with a person who has a specific health condition (dementia, diabetes, heart conditions - the list is endless).</p>
                    <p className="prominent">OmegaLife gives you a set of tools that allow you to stay informed around the clock.</p>
                </div>

                <div className="width-6">
                    <div className="feature">
                        <img src={Speech} alt="Speech icon" loading="lazy" />
                        <div>
                            <h4>Stay connected with your loved ones</h4>
                            <p>A built-in chat feature allows family and carers to communicate easily and efficiently, sharing key information to facilitate decision making.</p>
                        </div>            
                    </div>

                    <div className="feature">
                        <img src={Understand} alt="Understand behaviour icon" loading="lazy" />
                        <div>
                            <h4>Understand their common behaviour patterns</h4>
                            <p>The OmegaLife app gives a minimally-intrusive overview of common behaviour patterns so you can identify normal, everyday activities and start to recognise anomalies.</p>
                        </div>
                    </div>

                    <div className="feature">
                        <img src={Alerts} alt="Alerts icon" className='alerts' loading="lazy" />
                        <div>
                            <h4>Be alerted to incidents and potential problems as they happen</h4>
                            <p>OmegaLife can not only alert you when an emergency button is pressed, but also when behavioural anomalies occur. Has your loved one gone out at an unusual time of night? Have they missed their usual morning cup of tea? OmegaLife can be set up to alert you to any events you choose, so you can decide how best to respond.</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Features;
