function Cancel(props) {

    return (
        <div className="cover">
            <div className="cover-box">
                <button className="close-box" onClick={ (e) => props.closeAction(e) }>Close</button>
                <h3>Subscription cancelled</h3>
                <p>We have cancelled your subscription set up process. If you&rsquo;d like to set up a subscription, you can do so using the &quot;Get OmegaLife now&quot; section at the bottom of the page.</p>
            </div>
        </div>
    );
}

export default Cancel;
