import { Fragment } from "react";

function Portal(props) {

    return (
        <div className="cover">
            <div className="cover-box">
                <button className="close-box" onClick={ (e) => props.closeAction(e) }>Close</button>
                { props.registered ? (
                    <Fragment>
                        <h3>Welcome to OmegaLife</h3>
                        <p className="success">Your account registration has been successfully completed. Your OmegaLife equipment will be in the mail shortly - in the meantime we have emailed you instructions of how to get started on the OmegaLife app.</p>
                        <p>Should you need to update your payment details, you can do so at the link below (We have emailed a copy of this link to you for future reference):</p>
                    </Fragment>
                ) : (
                    <Fragment>
                        <h3>Update my subscription</h3>
                        <p>To update your subscription to OmegaLife, use the button below:</p>
                    </Fragment>
                )}
                
                <button className="btn block" onClick={ props.visitPortal }>Update subscription</button>
            </div>
        </div>
    );
}

export default Portal;
