import Logo from './../../assets/img/core/omega-life.png';

function HeaderAnimated(props) {

    return (
        <header>
            <div className="header-bg" style={{ opacity: props.headOpacity }}>&nbsp;</div> 
            <section className="content" id="mainHeader">
                <div className="width-3 logo">
                    <a href="/">
                        <img src={Logo} alt="OmegaLife logo"/>
                    </a>
                </div>
                <nav className="width-9">
                    <ul className="contact-us">
                        <li>Tel: <a className="contact-tel" href="tel:441274288939">01274 288939</a></li>
                        <li>Email: <a className="contact-email" href="mailto:hello@omega-life.co.uk">hello@omega-life.co.uk</a></li>
                    </ul>
                    { props.isNonHome ? (
                        <ul>
                            <li><a href="/">Home</a></li>
                        </ul>
                    ) : (
                        <ul>
                            <li><a href="#about" onClick={ (e) => props.scrollToSxn(e,"about") }>About OmegaLife</a></li>
                            <li><a href="#how" onClick={(e) => props.scrollToSxn(e, "how")}>How it works</a></li>
                            <li><a href="#buy" className="btn" onClick={(e) => props.scrollToSxn(e, "getOmega")}>Get it now</a></li>
                        </ul>
                    )}
                </nav>
            </section>
            <a href="#bookmark" className="favourite" onClick={(e) => props.addToFavourites(e)} title="Add this page to your favourites">Bookmark</a>
        </header>
    );
}

export default HeaderAnimated;
