import { Fragment } from "react";
import Cancel from "../../forms/cancel";
import Portal from "../../forms/portal";
import Success from "../../forms/success";

function GetOmegaLife(props) {

    return (
        <Fragment>
            {props.isCancelled && 
                <Cancel closeAction={ props.closeCancel } />
            }

            {props.subId && !props.hasPortalUrl &&
                <Success subId={ props.subId } updateForm={ props.updateForm } completeRegistration={ props.completeRegistration } error={ props.error } valid={props.valid}/>
            }

            { props.hasPortalUrl && 
                <Portal {...props}/>
            }

            <section className="get-it" id="getOmega">
                <div className='content'>

                    <div className="width-12">
                        <h2>Get OmegaLife now</h2>
                    </div>

                    <div className="width-4 package">
                        <h3>Compact</h3>
                        <h4>Perfect for flats and small houses</h4>

                        <ul>
                            <li>Full access to the OmegaLife app</li>
                            <li>Unlimited users on your account</li>
                            <li>OmegaLife setup equipment for one single residence (includes 1 Gateway, 1 Motion Detector, 1 Door Detector and 2 Panic Buttons)</li>
                            <li className="no-tick"><em>Requires broadband</em></li>    
                        </ul>

                        <button className="btn block" onClick={() => props.handleSubscribeRedirect("omega-life-compact") }>{ props.btns["omega-life-compact"] }</button>
                        <a href="mailto:hello@omega-life.co.uk" className="btn block faded tny-mtop">Speak to an expert</a>
                    </div>

                    <div className="width-4 package">
                        <h3>Comprehensive</h3>
                        <h4>Additional devices for a larger home</h4>

                        <ul>
                            <li>Full access to the OmegaLife app</li>
                            <li>Unlimited users on your account</li>
                            <li>OmegaLife setup equipment for one single residence (includes 1 Gateway, 2 Motion Detectors, 2 Door Detectors and 3 Panic Buttons)</li>
                            <li className="no-tick"><em>Requires broadband</em></li>    
                        </ul>

                        { /*<a href="mailto:hello@omega-life.co.uk" className="btn block">Express interest *</a>*/}
                        <button className="btn block" onClick={() => props.handleSubscribeRedirect("omega-life-full")}>{ props.btns["omega-life-full"] }</button>
                        <a href="mailto:hello@omega-life.co.uk" className="btn block faded tny-mtop">Speak to an expert</a>
                    </div>

                    <div className="width-4 package sml">
                        <h3 className="mbtm">Adventurer (Compact)</h3>

                        <ul>
                            <li>Everything in the &quot;Compact&quot; package plus emergency contact wristband (works without broadband)</li>
                        </ul>

                        <a href="mailto:hello@omega-life.co.uk" className="btn block faded">Coming soon: Express interest *</a>
                    </div>

                    <div className="width-4 package sml">
                        <h3 className="mbtm">Adventurer (Full)</h3>

                        <ul>
                            <li>Everything in the &quot;Comprehensive&quot; package plus an emergency contact wristband (works without broadband)</li>
                        </ul>

                        <a href="mailto:hello@omega-life.co.uk" className="btn block faded">Coming soon: Express interest *</a>
                    </div>

                    <div className="width-12 mtop package bespoke clear">
                        <h3>Custom</h3>
                        <h4>Tailor a package to your exact needs</h4>

                        <p>No two houses are the same, no two people have identical needs.</p>
                        <p>If you have specific needs or requirements from your OmegaLife kit, get in touch and our expert team can arrange a package to suit you.</p>
                        <ul>
                            <li>A solution tailored to your specific requirements</li>
                            <li>Cost effective pricing - based on the smart kit you need</li>
                            <li>Help from a person, not a computer</li>
                        </ul>
               
                        <a href="mailto:hello@omega-life.co.uk" className="btn block faded clear">Get in touch</a>
                    </div>

                    <p className="sml faded centre mtop">* We are currently in our testing phases for the Adeventurer kits. Express interest to find out more about the OmegaLife &quot;Adventurer&quot; packages and how they could help your loved ones - there&rsquo;s no obligation to buy.</p>
                </div>
            </section>
        </Fragment>
    );
}

export default GetOmegaLife;
