// Assets
import './assets/css/structure.css';
import './assets/css/core.css';

// Components
import Home from './components/templates/home';
import GA4React from "ga-4-react";
import Privacy from './components/templates/privacy';

function App() {

  // Identify the URL 
  const url = window.location.href;

  if (url.includes('my-data')) {
    return (
      <Privacy />
    );
  }

  return (
    <Home />
  );
}

try {
  setTimeout(_ => {
    const ga4react = new GA4React("G-79H8VPFMPZ");
    ga4react.initialize().catch(err => console.error(err));
  }, 4000);
} catch (err) {
  console.error(err);
}

export default App;
