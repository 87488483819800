import React, { useEffect, useState } from "react";

import GetOmegaLife from "../../../components/blocks/public/get";
import Fetching from './../../../utilities/fetching';

const queryString = require('query-string');

function GetOmegaLifeContainer(props) {

    const [isCancelled, setIsCancelled] = useState(false);
    const [sessionId, setSessionId] = useState(false);
    const [subId, setSubId] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [hasPortalUrl, setHasPortalUrl] = useState(false);
    const [error, setError] = useState(false);
    const [valid, setValid] = useState({});
    const [btns, setBtns] = useState({ "omega-life-compact": "Get it now", "omega-life-full": "Get it now" });
    const [completeDetails, setCompleteDetails] = useState({ firstName: "", lastName: "", email: "", address1: "", address2: "", address3: "", postcode: "" });

    // Close the cancelled lightbox
    const closeCancel = (e) => {
        e.preventDefault();
        setIsCancelled(false);
    }
    // Close the update lightbox
    const closeAction = (e) => {
        e.preventDefault();
        setHasPortalUrl(false);
        setSubId(false);
    }

    // Check cancellation
    const checkCancellation = () => {

        let loc = window.location.search;
        loc = queryString.parse(loc);

        if (typeof loc.canceled != "undefined" && loc.canceled === "true") {
            setIsCancelled(true);
        }

    }

    // Check success 
    // If we have a successful subscription set up then:
    // - Save the details based upon session (and retrieve the subscription ID)
    // - Open up the success form
    const checkSuccess = () => {
        let loc = window.location.search;
        loc = queryString.parse(loc);

        if (typeof loc.success != "undefined" && loc.success === "true" && typeof loc.session_id != "undefined") {

            // Store the session ID to state
            setSessionId(loc.session_id);

            // Store the session details
            fetch(process.env.REACT_APP_API_BASE + '/accounts/subscribe/store-subscription/' + loc.session_id, {
                method: 'get',
                headers: {
                    "Content-type": "application/json; charset=utf-8"
                }
            })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {
                
                if (typeof data.rslt != "undefined" && data.rslt === "success") {
                    // Store the subscription ID to state
                    setSubId(data.detail);
                }
                
            })
            .catch(function (error) {
                console.log('Request failed', error);
            });

        } else if (typeof loc.complete != "undefined") {

            setSubId(loc.complete);

        } else if (typeof loc.portal != "undefined") { 

            setSubId(loc.portal);
            setHasPortalUrl(true);

        }
    } 

    // Handle the redirect to the subscription
    const handleSubscribeRedirect = (productKey) => {

        var newBtns = { ...btns };
        newBtns[productKey] = "Redirecting...";
        setBtns(newBtns);

        fetch(process.env.REACT_APP_API_BASE + '/accounts/subscribe/create-subscription/' + productKey, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            
            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                window.location.href = data.detail;
                return;
            }

            var newBtns = { ...btns };
            newBtns[productKey] = "Get it now";
            setBtns(newBtns);
            
        })
        .catch(function (error) {
            console.log('Request failed', error);
            var newBtns = { ...btns };
            newBtns[productKey] = "Get it now";
            setBtns(newBtns);
        });

    }

    // Handle the form population
    const updateForm = (e, fieldName, value) => {

        e.preventDefault();
        var newDetails = { ...completeDetails }
        newDetails[fieldName] = value;
        setCompleteDetails(newDetails);

    } 

    // Complete the subscription / registration 
    const completeRegistration = () => {

        // Assemble the data to be submitted
        var subData = {...completeDetails};
        subData.subscriptionId = subId;

        setError(false);

        // Submit the data
        fetch(process.env.REACT_APP_API_BASE + '/accounts/subscribe/complete-subscription', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(subData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            
            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setRegistered(true);
                setHasPortalUrl(true);
                setValid({});
            } else if ( data.rslt === "already-completed" ) {
                setValid({});
                setError("This account registration has already been completed. If you believe this has occured in error, please contact our team (support@omega-life.co.uk).")
            } else if (data.rslt === "email-exists") {
                setValid({});
                setError("There is already an OmegaLife user registered with this email address - if you are creating a second account, please use a different email address. If you believe this has occured in error, please contact our team (support@omega-life.co.uk).")
            } else if (data.rslt === "req-valid-err") {
                setError("There is an error with your submission. Please check the errors below and try again.")
                setValid(data.detail);
            }
            
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    // Portal URL display
    const visitPortal = () => {

        // Retrieve a portal URL 
        fetch(process.env.REACT_APP_API_BASE + '/accounts/subscribe/portal-url/' + subId, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            
            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                window.location.href = data.detail;
            }
            
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    /* Upon load check whether this is a redirect */
    /* eslint-disable */
    useEffect(() => {
        checkCancellation();
        checkSuccess();
    }, []);
    /* eslint-enable */


    return (
        <GetOmegaLife 
            isCancelled={ isCancelled }
            closeCancel={ closeCancel }
            sessionId={sessionId}
            subId={subId}
            handleSubscribeRedirect={ handleSubscribeRedirect }
            completeDetails={ completeDetails }
            updateForm={ updateForm }
            completeRegistration={ completeRegistration }
            hasPortalUrl={ hasPortalUrl }
            visitPortal={ visitPortal }
            btns={ btns }
            registered={ registered }
            error={error}
            valid={ valid }
            closeAction={ closeAction }
        />
    );
}

export default GetOmegaLifeContainer;
